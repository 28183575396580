@import "../../assets/scss/Variables.scss";

.alectify-work-reports-container {
  padding: 5px;
  .alectify-work-order-reports-date-picker {
    width: 100%;
  }
  .alectify-work-order-reports-container {
    width: 100%;
    border-radius: 20px;
    background: $alectify-light-blue-bg;
    padding: 10px;
    .alectify-wo-reports-pm-download {
      .ant-form-item {
        margin: 0px;
      }
    }
  }
  .alectify-wo-asset-report-container {
    width: 100%;
    border-radius: 20px;
    background: $alectify-light-blue-bg;
    padding: 10px;
    .ant-form-item {
      margin: 0px;
    }
  }
  .alectify-work-reports-child-container {
    height: calc(100vh - 172px) !important;
    overflow-y: auto !important;
  }

  // .alectify-work-order-reports-date-picker {
    .ant-picker-input {
      input {
        &::placeholder {
          font-size: 14px !important;
          color: #999;
        }
      }
    }
  // }
}
