@import "../../../assets/scss/Variables.scss";

.alectify-approver-modal-description-container {
  padding: 8px;
}

.alectify-task-table-collaboration-column {
  .alectify-task-table-collaboration-count-text {
    color: $alectify-primary-blue;
  }
}

.title-container-wo {
  width: 200px;
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 0 !important;
  :hover {
    font-weight: bold;
  }
}

.text-link-title {
  text-decoration: underline;
  color: $alectify-primary-blue !important;
  cursor: pointer;
}

.no-text-link-title {
  color: rgb(77, 76, 76) !important;
  font-style: italic;
  font-size: 12px;
  cursor: default;
  text-decoration: none !important;
}

.work-id-container {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 140px;
}

.row-width {
  width: 100%;
  padding-right: 10px;
  display: flex;
}

.active-text {
  color: $alectify-primary-blue;
  &:hover {
    font-weight: bold;
  }
}

.simple-col-notifications {
  width: 100%;
  .procedure-col {
    padding-right: 10px;
    display: flex;
    .count-procedure {
      color: $alectify-primary-blue;
      margin-left: 3px;
    }
  }
  .alectify-task-table-collaboration-count-text {
    color: $alectify-primary-blue;
  }
}

.asset-col {
  width: 200px;
}
.work-order-create-modal {
  .ant-modal-body {
    height: calc(100vh - 200px);
    overflow-y: scroll;

    // For small screens (e.g., mobile)
    @media (max-width: 767px) {
      height: calc(100vh - 150px); // Adjust height for small screens
    }

    // For medium screens (e.g., tablets)
    @media (min-width: 768px) and (max-width: 1024px) {
      height: calc(100vh - 180px); // Adjust height for medium screens
    }

    // For large screens (e.g., desktop)
    @media (min-width: 1025px) {
      height: calc(100vh - 220px); // Adjust height for large screens
    }
  }

  .no-asset-option {
    color: rgb(56, 55, 55);
    font-style: italic;
    cursor: default;
  }
}
.review-completed {
  .ant-modal-body {
    height: calc(100vh - 600px);
    overflow-y: scroll;

    // For small screens (e.g., mobile)
    @media (max-width: 767px) {
      height: calc(100vh - 400px); // Adjust height for small screens
    }

    // For medium screens (e.g., tablets)
    @media (min-width: 768px) and (max-width: 1024px) {
      height: calc(100vh - 300px); // Adjust height for medium screens
    }

    // For large screens (e.g., desktop)
    @media (min-width: 1025px) {
      height: calc(100vh - 500px); // Adjust height for large screens
    }
  }

  .no-asset-option {
    color: rgb(56, 55, 55);
    font-style: italic;
    cursor: default;
  }
}
.pm-external-submit-for-review-modal {
  .ant-modal-body {
    height: calc(100vh - 300px);
    overflow-y: scroll;

    // For small screens (e.g., mobile)
    @media (max-width: 767px) {
      height: calc(100vh - 150px); // Adjust height for small screens
    }

    // For medium screens (e.g., tablets)
    @media (min-width: 768px) and (max-width: 1024px) {
      height: calc(100vh - 180px); // Adjust height for medium screens
    }

    // For large screens (e.g., desktop)
    @media (min-width: 1025px) {
      height: calc(100vh - 220px); // Adjust height for large screens
    }
  }

  .no-asset-option {
    color: rgb(56, 55, 55);
    font-style: italic;
    cursor: default;
  }
}
.ai-card-summary {
  background-color: transparent;
  .ant-popover-arrow {
    position: relative;
    width: 80px;
    height: 40px;
    bottom: -4px;
    left: 410px;
  }
}

.ai-table-summary {
  background-color: transparent;
  .ant-popover-arrow {
    position: relative;
    width: 80px;
    height: 40px;
    bottom: -4px;
    left: 21px;
  }
}

.ai-summary-work-order-popover {
  background-color: transparent;
  .ant-popover-arrow {
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 10px;
      background: #aa83f19a;
      height: 10px;
      border-bottom: 8px solid transparent;
      border-right: 8px solid transparent;

      .ant-divider-horizontal {
        margin: 8px 0 !important;
        border-block-start: 1px solid rgb(216, 218, 245) !important;
      }
    }

    &:before {
      width: 0;
      height: 0;
    }
  }

  .ant-popover-title {
    padding-left: 5px;
    padding-top: 2px;
    margin-bottom: 0;
    border-bottom: 1px solid #c8b2f0;
    overflow: hidden;
  }
  .ant-popover-inner-content {
    max-height: 300px;
    overflow: auto;
    padding: 0 10px;
  }
  .ant-popover-content {
    .ant-popover-inner {
      position: relative;
      z-index: 999;
      background-image: linear-gradient(
        to bottom,
        #e2d7f7,
        #e8e1f9,
        #efebfc,
        #f7f5fd,
        #ffffff
      );
      width: 500px;
      max-height: 408px;
      padding: 5px;

      border-radius: 10px;
    }
  }

  .ant-divider-horizontal {
    margin: 8px 0 !important;
    border-block-start: 1px solid #c8b2f0 !important;
  }
}

.ai-summary-wo-content {
  height: 100%;
  ul {
    padding: 10px;
    margin: 0;
  }
}

.carousel-modal-body {
  top: 10px;

  .ant-modal-title {
    background: none !important;
    background-color: rgba(0, 0, 0, 0.67) !important;
  }
  .ant-modal-body {
    height: 0rem;
    background-color: rgba(0, 0, 0, 0.45);
    padding: 0 !important;
  }
  .custom-arrow.slick-next {
    top: 237px !important;
    right: 2px !important;
  }

  .custom-arrow.slick-prev {
    top: 237px !important;
    left: 2px !important;
  }
  .empty-image {
    // margin-top: 9rem;
    height: 26rem;
    padding: 0;
    border-radius: 10px;

    .ant-upload.ant-upload-select {
      margin-bottom: 0px;
      background-color: rgba(0, 0, 0, 0.45) !important;
    }
    .ant-upload-wrapper {
      margin-bottom: 0;
    }

    svg {
      color: #fff;
    }

    .upload-msg-container {
      color: #fff;
    }
  }

  .carousel-item {
    height: 37rem;
    width: 40rem;
    background-color: rgba(0, 0, 0, 0.45);
  }
  .img-carousel {
    background-color: rgba(0, 0, 0, 0.45);
    height: 598px !important;
  }

  .slick-list {
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
  .slick-dots.slick-thumb {
    display: flex !important;
    height: 5.8rem;
    background-color: rgba(0, 0, 0, 0.45);
    margin-top: 0;
    align-items: center;
    justify-content: center;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;

    li {
      margin: 33px 7px;
      height: 32px !important;
    }
  }

  .image-counter {
    margin-top: 2px !important;

    color: #fff;
  }
  .slick-dots {
    margin-bottom: -67px;
  }
  .ant-modal-close {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    padding: 12px;
    width: 38px;
    height: 38px;
  }

  .custom-arrow.slick-next {
    top: 300px !important;
  }

  .custom-arrow.slick-prev {
    top: 300px !important;
  }

  &:hover {
    .upload-items {
      svg {
        color: $alectify-primary-blue;
      }
    }
    .upload-msg-container {
      color: rgb(109, 194, 250);
    }
  }
}
