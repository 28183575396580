@import "../../../assets/scss/Variables.scss";

.work-order-pm-attachment-container {
    height: calc(100vh - 172px);
    overflow: auto;
  padding: 5px;
  .ant-collapse-header-text {
    font-weight: 600;
  }
  .ant-collapse-item {
    background-color: $alectify-white;
  }
  .ant-collapse-header {
    align-items: center !important;
    padding: 12px 8px !important;
  }

  .ant-collapse-content {
    border-top: 1px solid $alectify-border-color;
  }

  .ant-collapse {
    border-top: 1px solid $alectify-border-color;
    border-right: 1px solid $alectify-border-color;
    border-left: 1px solid $alectify-border-color;
  }
  .ant-collapse-item {
    border-radius: 10px !important;
    border-bottom: 1px solid $alectify-border-color;
  }
  .work-order-pm-attachment-helper-text {
    font-weight: normal;
    font-size: 14px;
  }
}
