.toggle-button-container {
  margin-left: 10px;
  .active {
    background-color: #0954f1;
    span {
      color: #fff;
    }
  }
}

.alectify-ai-recommend-button {
  border-radius: 100px;
  background: linear-gradient(88deg, #0954f1 1%, #ff5fd6 153.41%);
  box-shadow: 0px 0px 14px 0px rgba(252, 72, 99, 0.11);
  color: #fff;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  padding: 8px !important;
  height: 30px !important;
  display: flex;
  align-items: center;
  font-size: 13px;
  justify-content: center;

  transition:
    background 0.3s ease,
    box-shadow 0.3s ease;
  &:hover {
    background: #0954f1;
    box-shadow: 0px 0px 18px 0px rgba(252, 72, 99, 0.25);
    color: #fff !important;
  }

  &:active {
    background: #0954f1;
    box-shadow: 0px 0px 8px 0px rgba(252, 72, 99, 0.25);
    transform: scale(0.98);
    color: #fff !important;
  }
  &.clicked {
    background: #0954f1;
  }
  &:disabled {
    cursor: not-allowed;
    color: #fff !important;
  }
}

.ai-summary-container {
  height: 100%;
  width: 100%;
}

.ai-summary-empty-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.alectify-ai-recommend-button.disabled {
  filter: grayscale(100%);
  pointer-events: none;
  opacity: 0.6;
}
/* Targetting first heading and removing margin from it as antd is applying margin to h1 @sarmad */
.ai-summary-container {
  h1:first-of-type {
    margin-top: 0;
  }
}