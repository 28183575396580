.alectify-ai-summary-button {
  border-radius: 100px;
  background: linear-gradient(88deg, #0954f1 1%, #ff5fd6 153.41%);
  box-shadow: 0px 0px 14px 0px rgba(252, 72, 99, 0.11);
  color: #fff;
  text-align: center;
  font-family: Rubik !important;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  transition:
    background 0.3s ease,
    box-shadow 0.3s ease;
  &:hover {
    background: #0954f1;
    box-shadow: 0px 0px 18px 0px rgba(252, 72, 99, 0.25);
    color: #fff !important;
  }

  &:active {
    background: #0954f1;
    box-shadow: 0px 0px 8px 0px rgba(252, 72, 99, 0.25);
    transform: scale(0.98);
    color: #fff !important;
  }
  &.clicked {
    // background: linear-gradient(88deg, #ff5fd6 1%, #0954f1 153.41%);
    background: #0954f1;
  }
  &:disabled {
    cursor: not-allowed;
    color: #fff !important;
  }
  .disabled {
    filter: grayscale(100%) !important;
    pointer-events: none !important;
    opacity: 0.6 !important;
  }
}
.alectify-ai-summary-button.disabled {
  filter: grayscale(100%) !important;
  pointer-events: none !important;
  opacity: 0.6 !important;
}
.alectify-ai-summary-dialog {
  background-color: #e8f1fd;
}

.custom-ai-summary-popover {
  border-end-start-radius: 60px;
  border-bottom-left-radius: 60px;
  border-bottom-right-radius: 60px;
  border-top-left-radius: 60px;
  box-shadow: 0px 0px 84px rgba(0, 0, 0, 0.3);
  .ant-popover-title {
    color: var(--Colors-Primary-Color, #0954f1);
    font-family: Rubik;
    font-size: 18px;
    font-style: normal;
    font-weight: bold;
    line-height: 22px; /* 110% */
    letter-spacing: -0.408px;
  }
  .ant-popover-content {
    .ant-popover-inner {
      padding: 30px !important;
      background-color: #ffff;
      width: 760px;
      height: 250px;
      text-align: justify;
      //   overflow-y: scroll;
      border-end-start-radius: 60px;
      border-bottom-left-radius: 60px;
      border-bottom-right-radius: 60px;
      border-top-left-radius: 60px;
    }
  }

  .ant-popover-inner {
    background-image: linear-gradient(
      to bottom,
      #e2d7f7,
      #e8e1f9,
      #efebfc,
      #f7f5fd,
      #ffffff
    );
  }
}

.custom-ai-summary-popover-top {
  border-end-start-radius: 60px;
  border-bottom-left-radius: 60px;
  border-bottom-right-radius: 60px;
  border-top-left-radius: 60px;
  border-top-right-radius: 60px;
  box-shadow: 0px 0px 84px rgba(0, 0, 0, 0.3);
  .ant-popover-title {
    color: var(--Colors-Primary-Color, #0954f1);
    font-family: Rubik;
    font-size: 18px;
    font-style: normal;
    font-weight: bold;
    line-height: 22px; /* 110% */
    letter-spacing: -0.408px;
  }
  .ant-popover-content {
    .ant-popover-inner {
      padding: 30px !important;
      background-color: #ffff;
      width: 760px;
      height: 250px;
      text-align: justify;
      border-end-start-radius: 60px;
      border-bottom-left-radius: 60px;
      //   border-bottom-right-radius: 60px;
      border-top-left-radius: 60px;
      border-top-right-radius: 60px;
    }
  }

  .ant-popover-inner {
    background-image: linear-gradient(
      to bottom,
      #e2d7f7,
      #e8e1f9,
      #efebfc,
      #f7f5fd,
      #ffffff
    );
  }
}

.ai-summary-content-container {
  overflow-y: scroll;
  height: 220px;
  padding: 5px 10px;
}

.alectify-ai-summary-container {
}
