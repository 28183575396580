@import "../../../../../../assets/scss/Variables.scss";

.data-round-timeline-container {
  .round-timeline-container {
    height: calc(
      100vh - 50vh
    ); // Ensures container has enough height for scrolling
    overflow: auto;
    background-color: #f5f8fc;
    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
      display: none;
    }
  
    // Hide scrollbar for Firefox
    scrollbar-width: none;
  
    // Hide scrollbar for Internet Explorer and Edge
    -ms-overflow-style: none;
    .infinite-scroll-component {
      margin-bottom: 6px;
    }
    ul {
      padding: 10px;
    }

    .ant-timeline-item {
      padding-bottom: 8px !important;
    }

    .round-timeline {
      .ant-timeline-item-label {
        width: calc(20% - 12px) !important;
        top: 4px;

        // Media Queries for Responsive Design
        @media (max-width: 1200px) {
          width: calc(20% - 10px) !important; // Adjust as needed
        }

        @media (max-width: 992px) {
          width: calc(20% - 8px) !important; // Adjust as needed
        }

        @media (max-width: 768px) {
          width: calc(20% - 6px) !important; // Adjust as needed
        }

        @media (max-width: 576px) {
          width: calc(20% - 4px) !important; // Adjust as needed
        }
      }

      .ant-timeline-item-tail {
        inset-inline-start: 20% !important;
        border-inline-start: 1px solid $alectify-primary-blue;
      }

      .ant-timeline-item-head {
        inset-inline-start: 20% !important;
        margin-top: 12px;
        background-color: transparent;
      }

      .ant-timeline-item-content {
        width: calc(80% - 200px) !important;
        inset-inline-start: calc(20% - 4px) !important;
        border: 1px solid $alectify-border-color;
        border-radius: 10px;
        padding: 0px 10px 0px 10px;
        background-color: #fff;
        // Media Queries for Responsive Design
        @media (max-width: 1200px) {
          width: calc(80% - 150px) !important;
        }

        @media (max-width: 992px) {
          width: calc(80% - 100px) !important;
        }

        @media (max-width: 768px) {
          width: calc(80% - 50px) !important;
        }

        @media (max-width: 576px) {
          width: calc(80% - 20px) !important;
        }
      }
      .timeline-arrow-icon {
        font-size: 15px !important;
      }
    }
  }
  .shift-card {
    padding: 5px 0px 5px 0px;
    background-color: #f6f6f6;
    background: #fff;
    // box-shadow: 0px 13px 25px 0px rgba(0, 0, 0, 0.15);
    // border-radius: 10px;
    border-bottom: 1px solid $alectify-border-color;

    &:last-child {
      border-bottom: none;
    }
  }
}

.time-icon {
  display: flex;
  align-items: center;
  gap: 2px;
  path {
    color: $alectify-primary-blue;
  }
}

.view-detail-button {
  width: 105px;
  margin: 0 !important;
  padding: 0 !important;
  font-size: 13px;
  height: 25px;
}

.cursor-not-allowed {
  cursor: not-allowed;

  .ant-typography {
    color: #999999;
  }
}

.timing-container {
  font-size: 12px;
  margin-left: 5px;
}
