.spare-parts-advisory-container {
  .spare-parts-advisory-detail-container {
    border-left: 1px solid rgb(157, 157, 157);
    padding-left: 10px;
    height: calc(100vh - 120px);
    overflow-y: scroll;
  }
  .spare-parts-advisory-list-container {
    padding-right: 10px;
    // margin-left: 10px;
    background-color: #e6eefe;
    height: calc(100vh - 120px);
    overflow-y: auto;
    padding: 10px;
    border-radius: 10px;
    .spare-parts-advisory-list-heading {
      font-size: 16px;
    }
    .spare-parts-advisory-list {
      .spare-parts-advisory-list-item {
        border-radius: 20px;
        padding: 10px;
        background-color: white;
        box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.27);
        cursor: pointer;
        &.active {
          background-color: #0000ff1c;
        }
      }
    }
  }
}
