.timing-container-history {
  font-size: 14px;
  margin-left: 5px;
}

.fields-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.field-row {
  padding-left: 10px;
  padding-right: 10px;
}

.history-timeline-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 16px;
  height: 50px;

  .round-range-picker-insight {
    height: 30px;
    margin-top: 17px;
  }
}

.toggle-section {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transform: translateY(-10px);
  transition:
    max-height 0.5s ease-in,
    opacity 0.5s ease-in,
    transform 0.5s ease-in;
}

.toggle-section.open {
  max-height: 300px;
  opacity: 1;
  transform: translateY(0);
}

.toggle-list {
  list-style-type: disc;
}

.toggle-list li {
  font-size: 14px;
  margin-bottom: 4px;
}

.rounds-history-timeline-card {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  margin: 5px 5px 0 8px;
  border-radius: 10px;
  margin-left: 1px;
}

.shift-date {
  border: 1px solid rgba(0, 0, 0, 0.096);
  padding: 5px 8px;
  background-color: #fff;
  border-radius: 10px;
}

.detail-col {
  border: 1px solid rgb(206, 231, 243);
  background-color: #f5f8fc;
  border-radius: 5px;
  height: 100%;
  height: calc(100vh - 124px);
}

.round-tag-history {
  border-radius: 20px;
}

.steps-tag-history {
  color: #000 !important;

  span {
    font-size: 10px;
  }
}

.issues-text {
  // border: 1px solid red;
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-top: 6px;
  border-radius: 4px;
  padding-left: 3px;
  padding-right: 3px;
}

.rounds-history-container {
  .ant-timeline-item-label {
    margin-top: 2px;
  }

  .round-timeline .ant-timeline-item-label {
    width: fit-content !important;
  }
  .ant-timeline {
    &.ant-timeline-label {
      .ant-timeline-item-tail,
      .ant-timeline-item-head {
        inset-inline-start: 7rem !important;
      }
    }

    .ant-timeline-item-tail {
      border-inline-start: 2px solid rgba(0, 83, 237, 0.253);
    }
  }

  .ant-timeline-item-content {
    inset-inline-start: calc(7rem - 10px) !important;
    width: calc(100% - 130px) !important;
    transition: width 0.3s ease-in-out;

    @media (max-width: 1200px) {
      inset-inline-start: 109px !important;
      width: calc(84% - 16px) !important;
    }

    @media (max-width: 1199px) {
      width: calc(83% - 10px) !important;
    }

    @media (max-width: 1143px) {
      width: calc(83% - 16px) !important;
    }

    @media (max-width: 1120px) {
      width: calc(83% - 20px) !important;
    }

    @media (max-width: 1064px) {
      width: calc(82% - 16px) !important;
    }

    @media (max-width: 1028px) {
      width: calc(81% - 16px) !important;
    }
  }

  .ant-timeline-item-head {
    margin-inline-start: -7px !important;
    width: 15px !important;
    height: 15px !important;
    border: 5px solid !important;
  }
}

.comment-text-effect-username {
  font-size: 12px;
  color: rgb(63, 62, 62);
  font-weight: 600;
}

.comment-text-effect-comment {
  font-size: 12px;
  color: black;
}

.timeline-container-history {
  height: calc(100vh - 173px);
  overflow: auto;
}

.comment-key-text {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.14px;
}

.shift-timing-container {
  margin-bottom: 10px;
  background: #f5f8fc;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 5px;
}

.red-dot {
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background-color: red;
  margin-top: 5px;
}

.bottom-container {
  padding: 5px;
}
