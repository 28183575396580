@import "../../assets/scss/Variables.scss";
.apexcharts-data-labels {
  overflow: visible !important;
}
.dashboard-container {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background: rgb(255, 255, 255);
  padding-left: 17px;
  margin-left: -17px;
  padding-right: 10px;

  h1 {
    font-size: 1.4rem;
  }
}
.stat-headings {
  color: #000103;
  font-size: 1.13rem !important;
  font-weight: 600;
  margin: 15px 0;
}

.stat-headings-v2 {
  color: #000103 !important;
  font-size: 1.13rem !important;
  font-weight: 600;
  margin: 10px 0;
}

.latest-activity-container {
  background: #f6f6f6;
  padding: 10px;
  height: 100%;
}

.cards-bottom {
  margin-top: 2.8rem;
}
.cards-body {
  box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.16);
  padding: 10px;
  min-height: 400px;
  @media screen and (min-width: 1921px) {
    height: 420px;
  }
  @media screen and (max-width: 1920px) {
    height: 400px;
  }
  @media screen and (max-width: 1440px) {
    height: 380px;
  }
  @media screen and (max-width: 1024px) {
    height: 350px;
  }
  @media screen and (max-width: 768px) {
    height: 320px;
  }
  border-radius: 30px;
  // cursor: pointer;

  // &:hover {
  .stat-headings-clickable {
    color: $alectify-primary-blue !important;
    text-decoration: underline;
    font-size: 18px;
    cursor: pointer;
  }
  // }
  .ant-badge-status-dot {
    height: 11px !important;
    width: 11px !important;
  }
  .donut-chart-name {
    margin-bottom: 30px;
    // color: var(--neutral-neutral-1000, #000);
    // font-feature-settings:
    //   "clig" off,
    //   "liga" off;
    // font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: bold;
    line-height: 20px; /* 142.857% */
    text-align: center;
    // margin-top: 10px;
  }
  .apexcharts-datalabel-value {
    color: var(--neutral-neutral-1000, #000);
    text-align: center;
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-family: Rubik;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 66.667% */
  }
}
.cards-body-external {
  box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.16);
  padding: 10px 20px 20px 30px;
  height: 100%;
  border-radius: 30px;
}

.annual-maintenance-body {
  background: #f6f6f6;
}

.dashboard-filter-btn {
  font-size: 16px;
  border: none;
  color: #0954f1;
}

.dashboard-filter-modal {
  .ant-modal-content {
    border-radius: 10px;
    .ant-modal-body {
      padding: 5px 8px;
    }
  }
  .ant-modal-content .ant-modal-title {
    background-color: transparent !important;
    height: 60px;
    padding: 15px 20px 5px;
    border-bottom: 1px solid #e4e4e4;
    display: flex;
    align-items: center;
  }

  .ant-checkbox + span {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 4px;
  }

  .dashboard-filter-container {
    max-height: 50vh;
    overflow-y: auto;

    .masterproject-filter-row {
      border-top: 1px solid #e4e4e4;
      padding: 6px 6px;
      display: flex;
      justify-content: space-between;
    }

    .masterproject-color {
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }
  }

  .filter-modal-title {
    color: #141b35;
    font-family: Rubik;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .filter-modal-sub-title {
    color: #888;
    font-family: Rubik;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.spare-parts-stats-card {
  h1.ant-typography {
    color: var(--neutral-neutral-1000, #000);
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  span.ant-typography {
    color: var(--Colors-Primary-Color, #0954f1);
    font-family: Rubik;
    font-size: 25px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
  }
}

.dashboard-data-table {
  .ant-table-row:last-child {
    .ant-table-cell {
      font-weight: bold;
    }
  }
}

.chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 343px;
}

.chart-container1 {
  .apexcharts-legend {
    top: -3px !important;
  }
}

.active-card-hover {
  :hover {
    .stat-headings {
      color: var(--alectify-primary-blue);
    }
  }
}
