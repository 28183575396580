.issue-present {
  .ant-slider-handle::after {
    box-shadow: 0 0 0 2px #e23906 !important;
    background-color: #f1160e !important;
  }
}

.no-issue {
  .ant-slider-handle::after {
    box-shadow: 0 0 0 2px #3fa104 !important;
    background-color: #1e9b1e !important;
  }
}

.medium-issue {
  .ant-slider-handle::after {
    box-shadow: 0 0 0 2px #bcbdbb !important;
    background-color: #b8b8b6 !important;
  }
}

.custom-slider {
  .ant-slider-dot {
    opacity: 0; // Hide all dots by default
    pointer-events: none;
  }

  .ant-slider-dot-active {
    opacity: 0 !important; // Show only the active dot
    pointer-events: auto;
  }
}

.slider-mark-custom {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 6rem;
}

.slider-text {
  position: absolute;
  font-size: 10px;
  border: 1px solid grey;
  color: #333;
  background: #fff;
  padding-left: 4px;
  padding-right: 4px;
  top: -35px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 6px;
  width: fit-content;
  white-space: nowrap;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);

  &::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: grey transparent transparent transparent;
  }
}

.rounds-status-container {
  .rounds-status-box {
    border: 1px solid;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
    width: fit-content;
    font-size: 12px;
  }
  .no-issue-box {
    color: rgb(2, 133, 23);
    border: 1px solid rgb(2, 133, 23);
    background-color: rgb(228, 248, 226);
  }

  .issue-box {
    background-color: rgb(253, 234, 234);
    border: 1px solid rgb(250, 34, 34);
    color: rgb(250, 34, 34);
  }

  .unknown-box {
    color: rgb(105, 103, 103);
    border: 1px solid rgb(105, 103, 103);
    background-color: rgba(230, 227, 227, 0.637);
  }
}

.device-status-container {
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 4px;
  font-size: 12px;
  color: #ffff;
}

.active-device-status {
  color: rgb(2, 133, 23);
  border: 1px solid rgb(2, 133, 23);
  background-color: rgb(228, 248, 226);
}

.not-active-device-status {
  background-color: rgb(253, 234, 234);
  border: 1px solid rgb(250, 34, 34);
  color: rgb(250, 34, 34);
}

.rotate-90 {
  transform: rotate(90deg);
  transition: transform 0.3s ease-in-out;
}

.transition-transform {
  transition: transform 0.3s ease-in-out;
}
